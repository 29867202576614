declare global {
    interface Window {
        env: EnvType;
    }
}
type EnvType = {
    VITE_API_URL: string;
    VITE_E2E_BASE_URL: string;
    VITE_ENVIRONMENT: string;
    VITE_GOOGLE_CLIENT_ID: string;
    VITE_STAFF_PORTAL_URL: string;
};
export const env: EnvType = { ...import.meta.env, ...window.env };
